import {$} from "../utils/utils.js";

$(window).on("load",function(){
	$(window).on("scroll",function(){
		if (!$(".loader-container").hasClass("active")) aos()
	});
})

function aos() {
	$("[data-aos]").each(function(){
		let isInView = window.innerWidth > 500 ? 100 : $(this).height() / 2;

		if($(this).data("aos")!="" && ($(this).isInViewport(isInView) || $(this).hasClass("aos-force"))){
			const that=this;
			const duration=$(this).data("aos-duration") || 800;
			const delay=$(this).data("aos-delay") || 0;
			$(this).css("-webkit-transition-duration",`${duration}ms`);
			setTimeout(function(){
				$(that).data("aos","");
				setTimeout(function(){
					loadAllMain();
				}, duration + 50)
			},delay)
		}
	});
}

window.aos=aos;