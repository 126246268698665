window.vcCounter = [];

$(window).on('load', function() {
    $('.vc-counter').each(function() {
        let settings = $(this).data('counter');
        delete this.dataset.counter;
        settings = settings ? JSON.parse(settings) : {};
        console.log("Init counter", settings)
        let counter = new VcCounter(this, settings);
        vcCounter.push(counter);
    });
}, {once: true});

class VcCounter {

    constructor(element, settings) {

        this.element = element;
        this.settings = settings;

        this.settings = {
            duration: parseInt(this.settings.duration) || 2000, // ms
            step: parseInt(this.settings.step) || 100,
            from: parseInt(this.settings.from) || 0,
            easing: this.settings.easing || 'linear',
            digits: parseInt(this.settings.digits) || 0,
            offset: parseInt(this.settings.offset) || 0,
            delay: parseInt(this.settings.delay) || 0,
        };

        this.init();

    }

    init() {
        this.number = parseInt(this.getNumberFromText());
        this.themplate = this.element.innerHTML.replaceAll(this.number, '{number}');
        this.currentNumber = this.settings.from;

        this.setDisplayNumber(this.currentNumber);

        this.event();
    }

    event() {
        let that = this;
        $(window).on('scroll', function() {
            if ($(that.element).isInViewport(that.settings.offset)) {
                that.animate();
                //$(window).off('scroll');
            }
        });

        if ($(that.element).isInViewport(that.settings.offset)) {
            that.animate();
            //$(window).off('scroll');
        }
    }

    getNumberFromText() {
        let text = $(this.element).text();

        let number = text.match(/\d+/g);
        if (number) {
            number = number.join('');
        } else {
            number = 0;
        }

        return number;
    }

    setDisplayNumber(number) {
        this.element.innerHTML = this.themplate.replaceAll('{number}', number);
    }

    animate() {
/*        if (this.settings.delay) {
            setTimeout(() => {
                this.animate();
            }, this.settings.delay);
            delete this.settings.delay;
            return;
        }*/

        if (this.inited) return;
        this.inited = true;


        let that = this;
        let duration = this.settings.duration;
        let start = performance.now();
        let end = start + duration;
        let from = this.settings.from;
        let to = this.number;
        let easing = easings.linear;

        function step() {
            let current = performance.now();
            let remaining = end - current;
            let number = (from + (to - from) * easing((duration - remaining) / duration)).toFixed(that.settings.digits);

            that.setDisplayNumber(number);

            if (current < end) {
                window.requestAnimationFrame(step);
            } else {
                that.setDisplayNumber(to);
            }
        }

        window.requestAnimationFrame(step);

    }

}

const easings = {
    linear: x => x,
    easeInQuad: function(x) {
        return x * x;
    },
    easeOutQuad: function(x) {
        return 1 - (1 - x) * (1 - x);
    },
    easeInOutQuad: function(x) {
        return x < 0.5 ? 2 * x * x : 1 - pow(-2 * x + 2, 2) / 2;
    },
    easeInCubic: function(x) {
        return x * x * x;
    },
    easeOutCubic: function(x) {
        return 1 - pow(1 - x, 3);
    },
    easeInOutCubic: function(x) {
        return x < 0.5 ? 4 * x * x * x : 1 - pow(-2 * x + 2, 3) / 2;
    },
    easeInQuart: function(x) {
        return x * x * x * x;
    },
    easeOutQuart: function(x) {
        return 1 - pow(1 - x, 4);
    },
    easeInOutQuart: function(x) {
        return x < 0.5 ? 8 * x * x * x * x : 1 - pow(-2 * x + 2, 4) / 2;
    },
    easeInQuint: function(x) {
        return x * x * x * x * x;
    },
    easeOutQuint: function(x) {
        return 1 - pow(1 - x, 5);
    },
    easeInOutQuint: function(x) {
        return x < 0.5 ? 16 * x * x * x * x * x : 1 - pow(-2 * x + 2, 5) / 2;
    },
    easeInSine: function(x) {
        return 1 - cos((x * PI) / 2);
    },
    easeOutSine: function(x) {
        return sin((x * PI) / 2);
    },
    easeInOutSine: function(x) {
        return -(cos(PI * x) - 1) / 2;
    },
    easeInExpo: function(x) {
        return x === 0 ? 0 : pow(2, 10 * x - 10);
    },
    easeOutExpo: function(x) {
        return x === 1 ? 1 : 1 - pow(2, -10 * x);
    },
    easeInOutExpo: function(x) {
        return x === 0
            ? 0
            : x === 1
                ? 1
                : x < 0.5
                    ? pow(2, 20 * x - 10) / 2
                    : (2 - pow(2, -20 * x + 10)) / 2;
    },
    easeInCirc: function(x) {
        return 1 - sqrt(1 - pow(x, 2));
    },
    easeOutCirc: function(x) {
        return sqrt(1 - pow(x - 1, 2));
    },
    easeInOutCirc: function(x) {
        return x < 0.5
            ? (1 - sqrt(1 - pow(2 * x, 2))) / 2
            : (sqrt(1 - pow(-2 * x + 2, 2)) + 1) / 2;
    },
    easeInBack: function(x) {
        return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function(x) {
        return 1 + c3 * pow(x - 1, 3) + c1 * pow(x - 1, 2);
    },
    easeInOutBack: function(x) {
        return x < 0.5
            ? (pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
            : (pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
    },
    easeInElastic: function(x) {
        return x === 0
            ? 0
            : x === 1
                ? 1
                : -pow(2, 10 * x - 10) * sin((x * 10 - 10.75) * c4);
    },
    easeOutElastic: function(x) {
        return x === 0
            ? 0
            : x === 1
                ? 1
                : pow(2, -10 * x) * sin((x * 10 - 0.75) * c4) + 1;
    },
    easeInOutElastic: function(x) {
        return x === 0
            ? 0
            : x === 1
                ? 1
                : x < 0.5
                    ? -(pow(2, 20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2
                    : (pow(2, -20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1;
    },
    easeOutBounce: function(x) {
        const n1 = 7.5625;
        const d1 = 2.75;

        if (x < 1 / d1) {
            return n1 * x * x;
        } else if (x < 2 / d1) {
            return n1 * (x -= 1.5 / d1) * x + 0.75;
        } else if (x < 2.5 / d1) {
            return n1 * (x -= 2.25 / d1) * x + 0.9375;
        } else {
            return n1 * (x -= 2.625 / d1) * x + 0.984375;
        }
    },
    easeInBounce: function(x) {
        return 1 - easings.easeOutBounce(1 - x);
    },
    easeInOutBounce: function(x) {
        return x < 0.5
            ? (1 - easings.easeOutBounce(1 - 2 * x)) / 2
            : (1 + easings.easeOutBounce(2 * x - 1)) / 2;
    },
};
