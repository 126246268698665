import { Tag } from './utils/tag.js';

fetch("/api/cms/getArticlesByCategories?category=2").then((res) => res.json()).then((data) => {
    generateOfferList(data);
}).catch((err) => {
    console.log(err);
});

let initOffers = false;

function generateOfferList(data) {

    if (!data) return;
    $(".offer-list").html("")

    for (const offer of data) {
        let tag = new Tag({
            tag: "div",
            text: offer.title,
        })

        $(".offer-list").append(tag);

        $(tag).on("click", function() {
            $(".offer-grid").fadeOut(350, function() {
                let y = $(".offer-grid").offset().top - 100;
                if (initOffers) $(window).scrollTo(0, y, 350);
                initOffers = true;

                $(".offer-list > div").removeClass("selected");
                $(tag).addClass("selected");
                $(".offer-content").html(offer.content);
                $(".offer-title").html(offer.title);

                let addData = JSON.parse(offer.additional_data)
                if (addData && addData.files) {
                    let files = JSON.parse(addData.files);
                    // Trim to max 4 files
                    files = files.slice(0, 4);

                    // Remove class from gallery
                    for (let i = 0; i <= 4; i++) {
                        $(".offer-gallery").removeClass(`offer-gallery-${i}`);
                    }

                    // Add class to gallery
                    $(".offer-gallery").addClass(`offer-gallery-${files.length}`)

                    let fileId = 0;
                    for (const file of files) {

                        let pic = $(".offer-gallery > picture")[fileId];
                        let img = $(pic).find("img")[0];

                        $(img).on("load", function() {
                            console.log("loaded");
                            setTimeout(function() {
                                $(img).fadeIn(350);
                                $(img).off("load");
                            }, 100);
                        });
                        $(img).fadeOut(100, function() {
                            // Set sources for picture and img
                            let sources = $(pic).find("source");
                            for (let i = 0; i < sources.length; i++) {
                                sources[i].srcset = `${file.url}`;
                            }

                            img.src = `${file.url}`;
                            img.alt = `${file.name || ""}`;
                        });

                        fileId++;
                    }

                }

                setTimeout(function() {
                    $(".offer-grid").fadeIn(350, function() {

                    });
                }, 50);
            });

        });
    }

    $(".offer-list > div")[0].classList.add("selected");
    // Trigger click on first element
    $(".offer-list > div")[0].click();


}