if(window.location.host.indexOf("-allsenses.pl")!=-1){
	try {
		const client=new WebSocket("ws://localhost:9999");
		client.addEventListener("message",function(event){
			const data=JSON.parse(event.data);
			if(data.type=="css"){
				console.log(`%cHot %cmodule %creplacement: %c${data.name}`,"color:#ff4810;background:#111;font-size:14px;font-weight:500","color:#ff8b36;background:#111;font-size:14px;font-weight:500","color:#b16630;background:#111;font-size:14px;font-weight:500","color:#000;background:#fff;font-size:14px;font-weight:500;border-radius:4px;padding:0 .5rem");
				$(`link[rel="stylesheet"]`).each(function(){
					const href=this.href.split("?")[0];
					if(href.endsWith(data.name)){
						this.href=`${href}?${Date.now()}`;
					}
				});
			}

			if(data.type=="js" || data.type=="php"){
				location.reload();
			}
		});
	} catch (e) {
		console.log("Hot module replacement is not available");
	}
}